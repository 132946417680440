import { useTranslation } from 'react-i18next';
import {
  Button,
  Heading,
  Icon,
  IconCopy,
  Text,
} from '@flixbus/honeycomb-react';
import { makeStyles } from '@material-ui/core';
import { useCopyToClipboard } from 'react-use';

const useStyles = makeStyles(() => ({
  pre: {
    backgroundColor: '#ecf0f1',
    padding: '15px',
    border: '1px solid #cccccc',
    display: 'block',
    fontSize: '14px',
    borderRadius: '4px',
    wordWrap: 'break-word',
    wordBreak: 'break-all',
  },
}));

const Secret = (props: { readonly secret?: string }) => {
  const { t } = useTranslation();
  const copyToClipboard = useCopyToClipboard()[1];
  const classes = useStyles();
  const { secret } = props;
  if (!secret) {
    return null;
  }

  return (
    <div>
      <Heading size={3}>{t('Decrypted Message')}</Heading>
      <Text>
        {t(
          'This secret might not be viewable again, make sure to save it now!',
        )}
      </Text>
      <Button appearance="primary" onClick={() => copyToClipboard(secret)}>
        <Icon InlineIcon={IconCopy} style={{fill: 'white'}} /> {t('Copy')}
      </Button>
      <pre id="pre" className={classes.pre}>
        {secret}
      </pre>
    </div>
  );
};

export default Secret;
