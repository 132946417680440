import { useTranslation } from 'react-i18next';
import { Controller, UseFormMethods } from 'react-hook-form';
import { Heading, Infobox, Input, Checkbox } from '@flixbus/honeycomb-react';

import '@flixbus/honeycomb-react/dist/css/honeycomb-helpers.css';

const CreateSecret = () => {
  const { t } = useTranslation();

  return <Heading size={3}>{t('Nothing to show!')}</Heading>;
};

export const Error = (props: {
  errors?: any;
  message?: string;
  onClick?: () => void;
}) => {
  return props.message ? (
    <Infobox appearance="danger" {...props}>
      {props.message}
    </Infobox>
  ) : null;
};

export const OneTime = (props: { control: UseFormMethods['control'] }) => {
  const { t } = useTranslation();

  return (
    <Controller
      control={props.control}
      name="onetime"
      defaultValue={true}
      render={({ onChange, value }) => {
        return (
          <Checkbox
            id="enable-onetime"
            value="enable-onetime"
            name="onetime"
            defaultChecked={value}
            onChange={(event) => onChange(event.target.checked)}
            label={t('One-time download')}
            small
          />
        );
      }}
    />
  );
};

export const SpecifyPasswordInput = (props: {
  control: UseFormMethods['control'];
}) => {
  const { t } = useTranslation();
  return (
    <Controller
      control={props.control}
      name="password"
      defaultValue=""
      render={({ onChange }) => (
        <Input
          label={t('Custom decryption key')}
          id="password"
          name="password"
          autoComplete="off"
          onChange={onChange}
        />
      )}
    />
  );
};

export const SpecifyPasswordToggle = (props: {
  defaultChecked: boolean;
  handleChange: any;
}) => {
  const { t } = useTranslation();

  return (
    <Checkbox
      id="generate-description-key"
      name="generateDecryptionKey"
      value="generateDecryptionKey"
      defaultChecked={props.defaultChecked}
      onChange={props.handleChange}
      label={t('Generate decryption key')}
      small
    />
  );
};

export default CreateSecret;
