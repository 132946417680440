import { useTranslation } from 'react-i18next';
import { Heading, Text } from '@flixbus/honeycomb-react';

const Error = (props: { error?: Error }) => {
  const { t } = useTranslation();

  if (!props.error) {
    return null;
  }

  return (
    <div>
      <Heading size={3}>{t('Secret does not exist')}</Heading>
      <Heading size={4}>
        {t('It might be caused by any of these reasons.')}
      </Heading>
      <br />
      <Text Elem="strong">{t('Opened before')}</Text>
      <Text>
        {t(
          'A secret can be restricted to a single download. It might be lost because the sender clicked this link before you viewed it.',
        )}
        <br />
        {t(
          'The secret might have been compromised and read by someone else. You should contact the sender and request a new secret.',
        )}

        <Text Elem="strong">{t('Broken link')}</Text>
        {t(
          'The link must match perfectly in order for the decryption to work, it might be missing some magic digits.',
        )}
        <Text Elem="strong">{t('Expired')}</Text>
        {t(
          'No secret last forever. All stored secrets will expires and self destruct automatically. Lifetime varies from one hour up to one week.',
        )}
      </Text>
    </div>
  );
};
export default Error;
