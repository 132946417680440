import { Header } from '@flixbus/honeycomb-react';

export const FlixHeader = () => {
  const brand = {
    alt: 'Flixvault',
    image: 'logo.svg',
    href: process.env.PUBLIC_URL,
  };

  return <Header fixed={false} brand={brand} navigation={[]} />;
};
