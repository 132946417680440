import { useCopyToClipboard } from 'react-use';
import { Button, Heading, Text, IconCopy } from '@flixbus/honeycomb-react';
import { useTranslation } from 'react-i18next';

import '@flixbus/honeycomb-react/dist/css/honeycomb-helpers.css';

type ResultProps = {
  readonly uuid: string;
  readonly password: string;
  readonly prefix: string;
};

const Result = ({ uuid, password, prefix }: ResultProps) => {
  const base = `https://flixvault.security.flix.tech/#/${prefix}`;
  const short = `${base}/${uuid}`;
  const full = `${short}/${password}`;
  const isCustomPassword = prefix === 'c' || prefix === 'd';
  const { t } = useTranslation();

  return (
    <div>
      <Heading extraClasses="hcr-space-md-top" size={1}>
        {t('Secret stored in database')}
      </Heading>
      <Text extraClasses="hcr-space-sm-bottom">
        {t(
          'Remember that the secret can only be downloaded once so do not open the link yourself.',
        )}
        <br />
        {t(
          'The cautious should send the decryption key in a separate communication channel.',
        )}
      </Text>
      {!isCustomPassword && (
        <SecretLinks label={t('One-click link')} value={full} />
      )}
      <SecretLinks label={t('Short link')} value={short} />
      <SecretLinks label={t('Decryption Key')} value={password} />
    </div>
  );
};

type SecretLinksProps = {
  readonly label: string;
  readonly value: string;
};

const SecretLinks = ({ label, value }: SecretLinksProps) => {
  const copyToClipboard = useCopyToClipboard()[1];

  return (
    <Text style={{ display: 'flex', alignItems: 'center' }}>
      <Button
        InlineIcon={IconCopy}
        display="square"
        onClick={() => copyToClipboard(value)}
      />
      <strong
        style={{ width: '125px' }}
        className="hcr-space-xs-right hcr-space-xs-left"
      >
        {label}
      </strong>
      <span style={{ fontWeight: 400 }}>{value}</span>
    </Text>
  );
};

export default Result;
