import { createMuiTheme } from '@material-ui/core/styles';

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#73d700"
    },
    secondary: {
      main: "#ffad00"
    },
  },
});
