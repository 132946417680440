import { HashRouter as Router } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import {
  ThemeWrapper,
  MainWrapper,
  PageContainer,
} from '@flixbus/honeycomb-react';

import { FlixHeader } from './shared/FlixHeader';
import { Routes } from './Routes';
import { Attribution } from './shared/Attribution';
import { theme } from './theme';

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <ThemeWrapper>
        <MainWrapper full>
          <Router>
            <FlixHeader />
            <PageContainer>
              <Routes />
              <Attribution />
            </PageContainer>
          </Router>
        </MainWrapper>
      </ThemeWrapper>
    </ThemeProvider>
  );
};

export default App;
