import { Link, Fineprint } from '@flixbus/honeycomb-react';
import { useTranslation } from 'react-i18next';

export const Attribution = () => {
  const { t } = useTranslation();
  return (
    <footer style={{
      position: "absolute",
      bottom: "0",
      right: "0",
      left: "0",
      textAlign: "center"
    }}>
      <Fineprint Elem="p">
        {t('Powered By Yopass Created By')}{' '}
        <Link href="https://github.com/jhaals/yopass">Johan Haals</Link>
      </Fineprint>
    </footer>
  );
};
